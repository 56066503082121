.color {
    &--primary {
        color: $fg-color-primary;
    }

    &--tertiary {
        color: $fg-color-tertiary;
    }

    &--danger {
        color: $fg-color-danger;
    }

    &--default {
        color: $fg-color-default
    }

    &-bg {
        &--success {
            background-color: #84AC61;
            color: white;

        }
    }
}

/* checkbox-circle */
.checkbox-circle input[type="checkbox"] {
    display: none;
}

.checkbox-circle input[type="checkbox"]+label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font: 14px/20px "Open Sans", Arial, sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.checkbox-circle input[type="checkbox"]:hover+label:hover {
    color: rgb(23, 86, 228);
}

.checkbox-circle input[type="checkbox"]:hover+label:before {
    border: 1px solid #343a3f;
    width: 1.2em;
    height: 1.2em;
    border: 2px solid #fff;
    background: #50565a;
    box-shadow: 0 0 0 1px #000;
}

.checkbox-circle input[type="checkbox"]+label:last-child {
    margin-bottom: 0;
}

.checkbox-circle input[type="checkbox"]+label:before {
    content: "";
    display: block;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #343a3f;
    border-radius: 1em;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.2s, transform 0.3s ease-in-out;
    transition: all 0.2s, transform 0.3s ease-in-out;
    background: #f3f3f3;
}

.checkbox-circle input[type="checkbox"]:checked+label:before {
    border-radius: 1em;
    border: 2px solid #fff;
    width: 1.2em;
    height: 1.2em;
    background: #50565a;
    box-shadow: 0 0 0 1px #000;
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}
