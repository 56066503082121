.title {
    font-weight: 600;
    text-transform: capitalize;

    &--tertiary {
        color: $fg-color-tertiary;
    }

    &--big {
        font-size: 3.2rem;
    }

    &--small {
        font-size: 2.4rem;
    }
}

.text {
    &--center {
        text-align: center;
    }

    &--left {
        text-align: left;
    }

    &--uppercase {
        text-transform: uppercase;
    }

    &--underline {
        text-decoration: underline;
    }
}
