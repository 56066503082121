@import 'colors';

body {
    .centered-page {
        display: flex;
        align-items: center;
        min-width: 100vw;
        min-height: 100vh;
    }



    .page-container {
        padding: 2rem;

        @include tablet-mobile {
            display: flex;
            flex-direction: column;
            min-height: 90vh;
            justify-content: space-around;
        }
    }

    .mobile-width {
        max-width: 50rem;
        margin-left: auto;
        margin-right: auto;

        @include tablet-mobile {
            width: 100%;
            max-width: 60%;
        }

        @include mobile-l {
            max-width: 70%;
        }

        @include mobile {
            max-width: 100%;
            padding: 0 2rem;
        }
    }
}
