.btn {
    height: 5rem;
    border-radius: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 2rem;
    cursor: pointer;
    border: none;
    outline: none;

    &-huge {
        height: 6rem;
    }

    &__img {
        height: 3rem
    }


    &.btn--primary {
        background-color: $bg-color-primary;
        color: $bg-color-default;
        outline: none;
        border: 1px solid $bg-color-primary;

        &:hover {
            background-color: darken($color: $bg-color-primary, $amount: 10);
        }

        &:disabled {
            pointer-events: none;
            background-color: $bg-color-primary-grey;
            border-color: lighten($color: $bg-color-primary-grey, $amount: 30);
        }
    }

    &.btn--outlined {
        border: 1px solid $bg-color-primary;
        color: $fg-color-primary;
        background-color: $bg-color-default;

    }

    &.btn--outlined-default {
        border: 1px solid $bd-color-default;
        color: $fg-color-default;
    }

    &.btn-fluid {
        display: block;
        width: 100%;
    }
}

.input {
    height: 5rem;
    outline: none;
    background-color: $bg-color-default;
    border-radius: .8rem;
    padding: 0 2rem;
    padding-right: 1rem;
    border: 1px solid $bg-color-primary;
    margin: 1rem 0 1rem 0;
    font-weight: 400;
    font-family: $font-default;
}


.form {
    &__container {
        display: flex;
        flex: 1;
        align-self: center;
        justify-content: center;
        align-items: center;
        gap: 10rem;
        height: 80vh;
        flex-wrap: wrap;

        @include mobile-l {
            margin-bottom: 12rem;
        }

        @include tablet-mobile {
            margin-bottom: 12rem;

        }
    }

    &__form {
        max-width: 50rem;
    }

    &-control {
        display: flex;
        flex-direction: column;

        label {
            font-weight: 500;
            color: $fg-color-primary;
        }
    }


}

.icon-button {
    cursor: pointer;
    display: inline-block;
    padding: .5rem 1rem;
    background-color: transparent;
    border: none;
    outline: none;

}

.line {
    height: 1px;
    border: 1px solid $bd-color-default;
}

.modal {
    border: 1px solid red;
}

.line {
    display: block;
    height: 1px;
    background-color: #D9D9D9;
}

.card-container {
    border: 1px solid $bd-color-default;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem 0;
}
