.padding {
    &-top {
        &-0 {
            padding-top: 0px !important;
        }

        &-8 {
            padding-top: 8px !important;

            @include mobile-l {
                padding-top: 4px !important;
            }
        }

        &-16 {
            padding-top: 16px !important;

            @include mobile-l {
                padding-top: 8px !important;
            }
        }

        &-24 {
            padding-top: 24px !important;

            @include mobile-l {
                padding-top: 12px !important;
            }
        }

        &-32 {
            padding-top: 32px !important;

            @include mobile-l {
                padding-top: 16px !important;
            }
        }

        &-40 {
            padding-top: 40px !important;

            @include mobile-l {
                padding-top: 20px !important;
            }
        }

        &-48 {
            padding-top: 48px !important;

            @include mobile-l {
                padding-top: 24px !important;
            }
        }

        &-64 {
            padding-top: 64px !important;

            @include mobile-l {
                padding-top: 32px !important;
            }
        }

        &-80 {
            padding-top: 80px !important;

            @include mobile-l {
                padding-top: 40px !important;
            }
        }

        &-100 {
            padding-top: 100px !important;

            @include mobile-l {
                padding-top: 50px !important;
            }
        }

        &-120 {
            padding-top: 120px !important;

            @include mobile-l {
                padding-top: 170px !important;
            }
        }

        &-160 {
            padding-top: 160px !important;

            @include mobile-l {
                padding-top: 250px !important;
            }
        }
    }

    &-bottom {
        &-0 {
            padding-bottom: 0px !important;
        }

        &-8 {
            padding-bottom: 8px !important;

            @include mobile-l {
                padding-bottom: 4px !important;
            }
        }

        &-16 {
            padding-bottom: 16px !important;

            @include mobile-l {
                padding-bottom: 8px !important;
            }
        }

        &-24 {
            padding-bottom: 24px !important;

            @include mobile-l {
                padding-bottom: 12px !important;
            }
        }

        &-32 {
            padding-bottom: 32px !important;

            @include mobile-l {
                padding-bottom: 16px !important;
            }
        }

        &-40 {
            padding-bottom: 40px !important;

            @include mobile-l {
                padding-bottom: 20px !important;
            }
        }

        &-48 {
            padding-bottom: 48px !important;

            @include mobile-l {
                padding-bottom: 24px !important;
            }
        }

        &-64 {
            padding-bottom: 64px !important;

            @include mobile-l {
                padding-bottom: 32px !important;
            }
        }

        &-80 {
            padding-bottom: 80px !important;

            @include mobile-l {
                padding-bottom: 40px !important;
            }
        }

        &-100 {
            padding-bottom: 100px !important;

            @include mobile-l {
                padding-bottom: 50px !important;
            }
        }

        &-120 {
            padding-bottom: 120px !important;

            @include mobile-l {
                padding-bottom: 170px !important;
            }
        }

        &-160 {
            padding-bottom: 160px !important;

            @include mobile-l {
                padding-bottom: 250px !important;
            }
        }
    }

    &-left {
        &-0 {
            padding-left: 0px !important;
        }

        &-8 {
            padding-left: 8px !important;

            @include mobile-l {
                padding-left: 4px !important;
            }
        }

        &-16 {
            padding-left: 16px !important;

            @include mobile-l {
                padding-left: 8px !important;
            }
        }

        &-24 {
            padding-left: 24px !important;

            @include mobile-l {
                padding-left: 12px !important;
            }
        }

        &-32 {
            padding-left: 32px !important;

            @include mobile-l {
                padding-left: 16px !important;
            }
        }

        &-40 {
            padding-left: 40px !important;

            @include mobile-l {
                padding-left: 20px !important;
            }
        }

        &-48 {
            padding-left: 48px !important;

            @include mobile-l {
                padding-left: 24px !important;
            }
        }

        &-64 {
            padding-left: 64px !important;

            @include mobile-l {
                padding-left: 32px !important;
            }
        }

        &-80 {
            padding-left: 80px !important;

            @include mobile-l {
                padding-left: 40px !important;
            }
        }

        &-100 {
            padding-left: 100px !important;

            @include mobile-l {
                padding-left: 50px !important;
            }
        }

        &-120 {
            padding-left: 120px !important;

            @include mobile-l {
                padding-left: 170px !important;
            }
        }

        &-160 {
            padding-left: 160px !important;

            @include mobile-l {
                padding-left: 250px !important;
            }
        }
    }

    &-right {
        &-0 {
            padding-right: 0px !important;
        }

        &-8 {
            padding-right: 8px !important;

            @include mobile-l {
                padding-right: 4px !important;
            }
        }

        &-16 {
            padding-right: 16px !important;

            @include mobile-l {
                padding-right: 8px !important;
            }
        }

        &-24 {
            padding-right: 24px !important;

            @include mobile-l {
                padding-right: 12px !important;
            }
        }

        &-32 {
            padding-right: 32px !important;

            @include mobile-l {
                padding-right: 16px !important;
            }
        }

        &-40 {
            padding-right: 40px !important;

            @include mobile-l {
                padding-right: 20px !important;
            }
        }

        &-48 {
            padding-right: 48px !important;

            @include mobile-l {
                padding-right: 24px !important;
            }
        }

        &-64 {
            padding-right: 64px !important;

            @include mobile-l {
                padding-right: 32px !important;
            }
        }

        &-80 {
            padding-right: 80px !important;

            @include mobile-l {
                padding-right: 40px !important;
            }
        }

        &-100 {
            padding-right: 100px !important;

            @include mobile-l {
                padding-right: 50px !important;
            }
        }

        &-120 {
            padding-right: 120px !important;

            @include mobile-l {
                padding-right: 170px !important;
            }
        }

        &-160 {
            padding-right: 160px !important;

            @include mobile-l {
                padding-right: 250px !important;
            }
        }
    }
}
