@import "mixins";
@import "variables";
@import "colors";
@import "base";
@import "utils/utils";
@import "components";
@import "typography";
@import "container";

.page-container {
    padding: 2rem;

    @include only-desktop {
        padding: 5rem;
    }

    @include mobile-l {
        padding: 2rem;
    }

    @include mobile {
        padding: 1.5rem;
    }
}
