.flex {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &--1 {
    flex: 1;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &.items-center {
    align-items: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.content-center {
    justify-content: space-around;
    justify-content: center;
  }

  &.gap {
    &--1 {
      gap: 1rem;
    }

    &--2 {
      gap: 2rem;
    }
    &--3 {
      gap: 3rem;
    }

    &--4 {
      gap: 4rem;
    }

    &--5 {
      gap: 5rem;
    }

    &--6 {
      gap: 6rem;
    }
  }
}
