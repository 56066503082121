:root {
    --bg-color-primary: "#2E318C";
    --bg-color-primary-grey: rgba(46, 49, 140, 0.5);
    --bg-color-default: "#fff";
    --fg-color-primary: "#2E318C";
    --fg-color-primary-grey: rgba(46, 49, 140, 0.5);
    --fg-color-default: "#333333";
    --fg-color-tertiary: "#B97E3F";
    --fg-color-link: "#2E318C";
    --font-default: 'Rubik', sans-serif;
    --font-title: 'Rubik', sans-serif;
    --bd-color-default: #D1D5DB;
    --bd-color-primary: #2E318C;
    --bd-color-primary-grey: rgba(46, 49, 140, 0.5);


}

$bg-color-primary: #2E318C;
$bg-color-primary-grey: rgba(46, 49, 140, 0.5);
$bg-color-default: #fff;
$fg-color-primary: #2E318C;
$fg-color-primary-grey: var(--fg-color-primary-grey);
$fg-color-default: #333333;
$fg-color-white: #fff;
$fg-color-tertiary: #B97E3F;
$color-link: #2E318C;
$font-default: var(--font-default);
$font-title: var(--font-title);
$font-special-heading: var(--font-special-heading);
$bd-color-default: #D1D5DB;
$bd-color-primary: #2E318C;
$fg-color-danger: #EF1717;
